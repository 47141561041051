import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ResetPasswordForm } from './ResetPasswordForm';
import AuthLayout from '../auth-layout';

export const ResetPassword = () => {
  return (
    <AuthLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left',
          flexDirection: 'column',
          margin: { xs: '1rem', md: '10px 0' },
          padding: '10px 40px 30px 40px',
        }}
      >
        <Typography
          component="h2"
          sx={{ fontSize: { xs: '32px', sm: '40px', md: '55px' } }}
        >
          Reset Password
        </Typography>
      </Box>
      <ResetPasswordForm />
    </AuthLayout>
  );
};
