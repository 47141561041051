export const DashboardIframe = [
  {
    role: 'superadmin',
    program: 'pact',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/f2904e01-ce00-4fdd-95eb-c36d5e94c573`,
  },
  {
    role: 'admin',
    program: 'pact',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/f2904e01-ce00-4fdd-95eb-c36d5e94c573`,
  },
  {
    role: 'user',
    program: 'benefit',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/d1ef14ad-d618-4f27-9ae4-39bebfeea0eb`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'ilia beauty',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/ab3d2701-b0b1-4682-b33d-2a181b106c04`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'glow recipe',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/ae48a98a-53ff-480d-97f4-628366e03e53`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'credo',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/43d13e63-67f0-42a5-ab91-82bdea82f091`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'hudsons bay company',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/677a011d-463d-48a3-9fce-e4451530b453`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'loreal usa',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/6879f7fe-497f-45fc-8b75-443d80e3acde`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'nordstrom',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/9b002a99-84b2-4c71-a5b2-bcd3781595f5`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'ulta',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/f56ff568-1361-443f-9341-a0e78321ef84`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'winners',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/46ea44e8-cdbc-4d27-b7a0-120a73e92fdf`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'kendo beauty',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/e0b82fc0-f1f1-4a34-ab15-4a1af5164a04`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'sephora',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/34ddabe1-e0aa-4daa-a882-60cb19600827`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'ritual',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/276095b5-c3c0-4a2d-bf8f-2281cb49adc4`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: 'saie',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/943b5e6c-bd3e-4fe4-bcda-03027814a4f5`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: "l'occitane",
    iframeUrl: `https://app.trevor.io/embedded/dashboard/eaa33084-e54c-4a36-87b8-56d18132e0a9`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
  {
    role: 'user',
    program: "saks fifth avenue new york",
    iframeUrl: `https://app.trevor.io/embedded/dashboard/04d93436-2159-48c7-9635-7c42c45f3d17`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`,
  },
];
