import { ReactNode, FC } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import LoginBannerSrc from 'assets/images/login.jpg';
import logo from 'assets/images/logo.svg';

interface IAuthLayout {
  children: ReactNode;
}

const AuthLayout: FC<IAuthLayout> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#DCE4E2',
        minHeight: '100vh',
        paddingTop: '40px',
      }}
    >
      <Box
        component="img"
        sx={{
          objectFit: 'cover',
          borderRadius: '5px',
        }}
        alt="Logo"
        src={logo}
      />
      <Card
        sx={{
          width: '85vw',
          margin: { xs: '2px auto', md: '40px auto' },
          padding: '20px',
          '& .MuiCardContent-root': {
            paddingBottom: '0 !important',
          },
        }}
      >
        <CardContent sx={{ padding: 0 }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box
                component="img"
                sx={{
                  marginTop:'18px',
                  objectFit: 'contain',
                  height: '95%',
                  width: '100%',
                  borderRadius: '5px',
                }}
                alt="The Blog Banner"
                src={LoginBannerSrc}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              {children}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AuthLayout;
