import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import LoginBannerSrc from 'assets/images/login.jpg';
import { ForgetPasswordForm } from './ForgetPasswordForm';
import AuthLayout from '../auth-layout';

export const ForgetPassword = () => {
  return (
    <AuthLayout>
      <Box
       sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'left',
        flexDirection: 'column',
        margin: { xs: '1rem', md: '10px 0' },
        padding: '10px 40px 30px 40px',
      }}
      >
        <Typography
          component="h2"
          sx={{ fontSize: { xs: '32px', sm: '40px', md: '55px' } }}
        >
          Forgot Password
        </Typography>
      </Box>
      <ForgetPasswordForm />
    </AuthLayout>
  );
};
