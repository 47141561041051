import { Box } from '@mui/material';
import { DashboardIframe } from 'helpers';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAxios, useSidebar } from 'hooks';
import { SidebarTypeEnum } from 'hooks/useSidebar';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuthContext } from 'context';
import Button from '@mui/material/Button';
import { colors, fontsWeight } from 'utils';

interface IIframe {
  role: string;
  program: string;
  iframe: string;
}
export const Dashboard = () => {
  const sidebarStore = useSidebar();
  const { logoutUser } = useAuthContext();

  const [loading, setLoading] = useState(true);
  const [hasIframe, setHasIframe] = useState(null);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const AxiosClient = useAxios();


  const user = JSON.parse(
    localStorage.getItem('user') ? localStorage.getItem('user') : '{}'
  );

  const fetchDashboardUrl =useCallback(
    async (url) => {
      try {
        const data = url.split("/");
  
        const sourceId = data[data.length - 1];
        const sourceType = data[data.length - 2];
  
        const { data:response } = await AxiosClient.get(`addresses/get-dashboard?resource_type=${sourceType}&resource_id=${sourceId}`);
        
        setIframeUrl(response?.dashboardUrl);
      } catch (error) {
        console.error("Error dashboard public URL:", error);
      }
    },[AxiosClient])
  

  useEffect(() => {
    setLoading(true)
    const dashboardType = searchParams.get("dashboardType")

    const hasRoleIframe = DashboardIframe.find(
      (item) =>
        item.role === user.role &&
        user?.programs
          ?.map((program) => program.toLowerCase())
          .includes(item.program.toLowerCase())
    );

    if (hasRoleIframe) {
      setHasIframe((prev) => ({ ...prev, ...hasRoleIframe }));
      hasRoleIframe?.iframeUrl !== '' &&
     dashboardType==='program'? fetchDashboardUrl(hasRoleIframe.programUrl):fetchDashboardUrl(hasRoleIframe.iframeUrl);
    } 
    setLoading(false);
    return;
  }, [DashboardIframe, user.role,searchParams]);

  const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    logoutUser();
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '0 16px',
        }}
      >
        <MenuIcon
          onClick={() => sidebarStore.onOpen(SidebarTypeEnum.NAVIGATION)}
        />
        <Button
          onClick={handleLogout}
          sx={{
            ...ButtonStyles,
          }}
          variant="outlined"
        >
          Logout
        </Button>
      </Box>
      {hasIframe && hasIframe.iframe !== '' ? (
        <div>
          <iframe
            title="dashboard"
            style={{
              width: '100%',
              height: '900px',
              border: '1px solid #ddd',
              borderRadius: '4px',
            }}
            src={iframeUrl}
          ></iframe>
        </div>
      ) :
      (
        <Navigate to="/addresses" />
      )}
    </Box>
  );
};

const ButtonStyles = {
  width: '188px',
  height: '40px',
  background: colors.lochinvar,
  color: colors.colorWhite,
  borderRadius: 0,
  fontWeight: fontsWeight.fontMedium,
  fontSize: '16px',
  lineHeight: '24px',
  outline: 'none',
  border: 'none',
  textTransform: 'capitalize',
  '&.Mui-disabled,&.Mui-disabled:hover ': {
    background: colors.lightMediumGray,
    pointerEvents: 'all',
    cursor: 'progress',
  },
  '&:hover': {
    background: colors.lochinvar,
    border: 'none',
  },
};
