import { Address } from 'interfaces';
const dayjs = require('dayjs');

export const changeAddressFieldsName = (data: Address) => {
  return {
    'Pact ID': data?.id,
    'G2 ID': data?.uniqueId ?? '',
    'Date Generated': dayjs(data.createdAt).format('MMM DD, YYYY'),
    Name: `${data.firstName} ${data.lastName.split('-')[0]}`,
    Carrier: data.carrier,
    Member: data.brand.name,
    'Program Type': data.program,
    Email: data.email,
    'Email Status': data.emailStatus,
    Attention: data.recycleDonate,
    'Tracking #': data.trackingNumber,
    'Delivery Status': data?.status || '-',
    'Date of Delivery': data?.deliveryDate || '-',
    'Address Line 1': data.addressLine1,
    'Address Line': data.addressLine2,
    City: data.city,
    ZIP: data.zipcode,
    State: data.state,
    Country: data.country,
    'Bin Processed Date': data?.bin_processed_date,
    'Store Number': data.storeNumber,
    'Kit Shipping Date': data.shippingDate
      ? dayjs(data.shippingDate).format('MMM DD, YYYY')
      : '',
    'Kit outbound tracking': data.outbound,
    'Bin inbound tracking': data.inbound,
    '#4 LDPE': data.ldpe,
    Glass: data.glass,
    Silicone: data.silicone,
    Cardboard: data.cardboard,
    '#5 PP': data.pp,
    Metal: data.metal,
    '#7 Other': data.other,
    Contaminated: data.contaminated,
    '#2 HDPE': data.hdpe,
    'Mixed Paper': data.mixedPaper,
    '#3 PVC': data.pvc,
    '#1 PET': data.pet,
    '#6 PS': data.ps,
    Ceramic: data.ceramic,
    ABS: data?.abs,
    LED: data?.led,
    'Plastic Metal': data?.plastic_metal,
    PC: data?.pc,
    'Waste To Energy': data?.waste_to_energy,
    'Misc. Recyclable Materials': data.miscRecyclableMaterials,
    '# of donated tools': data.hotToolPieces,
    'Hot Tool Donation Weight': data.weight,
    '# of recycled tools': data.recycleWeight,
    'weight of recycled tool': data.recyclePieces,
    'Waste To Concrete': data?.waste_to_concrete,
  };
};
