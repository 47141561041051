import { InputField } from 'components';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { isError, isErrorMessage } from 'helpers';
import { useResetPasswordFormSchema, useSubmitReset } from 'views/home/hooks';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Typography from '@mui/material/Typography';

interface DecodedJwt {
  email: string;
  iat: Date;
  exp: Date;
  role: string;
  id: number;
}
export const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState('');

  const loginFormSchema = useResetPasswordFormSchema();
  const { initialValues, onSubmit } = useSubmitReset(email);
  const { token } = useParams();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const decodeJwt = (token) => {
      const decoded: DecodedJwt = jwt_decode(token, {
        header: false,
      }) as DecodedJwt;
      const { iat, exp, ...user } = decoded;

      setEmail(user.email);
    };

    decodeJwt(token);
  }, [token]);

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: loginFormSchema,
    onSubmit,
  });

  return (
    <Box
      component="form"
      sx={{
        padding: { xs: '0 30px', md: '0 40px' },
      }}
      onSubmit={handleSubmit}
    >
      <Typography>Password</Typography>
      <InputField
        fullWidth
        type={showPassword ? 'text' : 'password'}
        label="Password"
        variant="outlined"
        helperText={isErrorMessage('password', errors)}
        error={isError('password', errors, touched)}
        {...getFieldProps('password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                sx={{ marginRight: '1px' }}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography>Confirm Password</Typography>
      <InputField
        fullWidth
        type={showConfirmPassword ? 'text' : 'password'}
        label="Confirm password"
        variant="outlined"
        helperText={isErrorMessage('confirmPassword', errors)}
        error={isError('confirmPassword', errors, touched)}
        {...getFieldProps('confirmPassword')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownPassword}
                sx={{ marginRight: '1px' }}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
         type="submit"
         variant="contained"
         fullWidth
         sx={{
           marginBottom: {
             xs: '13px',
             md: '28px',
             backgroundColor: '#2E888D',
             height: '3rem',
             textTransform: 'none',
             '&:hover': {
               backgroundColor: '#257473',
             },
           },
         }}
        disabled={!(isValid && dirty) || isSubmitting}
      >
        Update Password
      </Button>
    </Box>
  );
};
