import { useAuthContext } from "context";
import { FormikHelpers } from "formik";
import { AxiosClient } from "services";
import { IResetInitialValues, ITokens } from "views/home/interfaces";
import { useToast } from "hooks";
import { transformError } from "helpers";

const initialValues: IResetInitialValues = {
  password: "",
  confirmPassword: "",
  email: "",
};

export const useSubmitReset = (email) => {
  const toast = useToast();
  const { authenticateUser } = useAuthContext();
  const onSubmit = async (
    values: IResetInitialValues,
    actions: FormikHelpers<IResetInitialValues>
  ) => {
    try {
      values.email = email;
      const result = await AxiosClient.post("auth/reset-password", values);
      authenticateUser(result.data.access_token);
    } catch (err) {
      toast.error(transformError(err).message);
      actions.setSubmitting(false);
    }
  };

  return {
    initialValues,
    onSubmit,
  };
};
