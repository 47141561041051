import { FormikHelpers } from "formik";
import { AxiosClient } from "services";
import { IForgetInitialValues, ITokens } from "views/home/interfaces";
import { useToast } from "hooks";
import { transformError } from "helpers";

const initialValues: IForgetInitialValues = {
  email: "",
};

export const useSubmitForget = () => {
  const toast = useToast();
  const onSubmit = async (
    values: IForgetInitialValues,
    actions: FormikHelpers<IForgetInitialValues>
  ) => {
    try {
      const result = await AxiosClient.post("auth/forget-password", values);
      toast.success(
        result?.data?.message || "Check your email to reset password"
      );
      values.email = "";
    } catch (err) {
      toast.error(transformError(err).message);
      actions.setSubmitting(false);
    }
  };

  return {
    initialValues,
    onSubmit,
  };
};
