import { AuthRedirect, RequireAuth } from 'components';
import { Routes as Router, Route } from 'react-router-dom';
import {
  Addresses,
  CreateUser,
  ForgetPassword,
  Home,
  ResetPassword,
} from 'views';
import { Dashboard } from 'views/addresses/Dashboard';
import { Users } from 'views/users/Users';

export const Routes = () => {
  return (
    <Router>
      {/* public routes */}
      <Route element={<AuthRedirect />}>
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/" element={<Home />} />
      </Route>

      {/* Private routes */}
      <Route element={<RequireAuth />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="addresses" element={<Addresses />} />
        <Route path="create-user" element={<CreateUser />} />
        <Route path="users" element={<Users />} />
      </Route>

      {/* catch all */}
      <Route path="*" element={<p>Missing Route</p>} />
    </Router>
  );
};
