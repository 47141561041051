import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LoginForm } from 'views/home/components';
import AuthLayout from './components/auth-layout';

export const Home = () => {
  return (
    <AuthLayout>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left',
          flexDirection: 'column',
          margin: { xs: '1rem', md: '10px 0' },
          padding: '10px 40px 30px 40px',
        }}
      >
        <Typography
          component="h2"
          sx={{ fontSize: { xs: '32px', sm: '40px', md: '55px' } }}
        >
          Admin Login
        </Typography>
        <Typography>
          Pact’s Collection Program Database is your tool for receiving
          real-time access to your program data. If you have questions, please
          reach out to us at{' '}
          <a
            href="mailto:hello@pactcollective.org"
            style={{ color: '#2E888D', textDecoration: 'none' }}
          >
            hello@pactcollective.org.
          </a>
        </Typography>
      </Box>
      <LoginForm />
    </AuthLayout>
  );
};
