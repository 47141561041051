import { InputField } from 'components';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { isError, isErrorMessage } from 'helpers';
import { useForgetPasswordFormSchema, useSubmitForget } from 'views/home/hooks';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const ForgetPasswordForm = () => {
  const loginFormSchema = useForgetPasswordFormSchema();
  const { initialValues, onSubmit } = useSubmitForget();

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema: loginFormSchema,
    onSubmit,
  });

  return (
    <Box
      component="form"
      sx={{
        padding: { xs: '0 30px', md: '0 40px' },
      }}
      onSubmit={handleSubmit}
    >
      <Typography>Email</Typography>
      <InputField
        fullWidth
        label="Email"
        variant="outlined"
        helperText={isErrorMessage('email', errors)}
        error={isError('email', errors, touched)}
        {...getFieldProps('email')}
      />

      <Button
         type="submit"
         variant="contained"
         fullWidth
         sx={{
           marginBottom: {
             xs: '13px',
             md: '28px',
             backgroundColor: '#2E888D',
             height: '3rem',
             textTransform: 'none',
             '&:hover': {
               backgroundColor: '#257473',
             },
           },
         }}
        disabled={!(isValid && dirty) || isSubmitting}
      >
        Send Email
      </Button>
    </Box>
  );
};
