import * as Yup from "yup";

export const useResetPasswordFormSchema = (): Yup.AnyObject => {
  return Yup.object().shape({
    password: Yup.string().required("Password is required!"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required!"),
  });
};
